<script lang="ts">
	import { PUBLIC_ENV_CONFIG } from '~/utils/env-config';
</script>

<svelte:head>
	{#if PUBLIC_ENV_CONFIG.MODE === 'production'}
		<script async src="https://www.googletagmanager.com/gtag/js?id=G-D9R7Y5LZJ3"></script>
		<script>
			window.dataLayer = window.dataLayer || [];
			function gtag() {
				dataLayer.push(arguments);
			}
			gtag('js', new Date());

			gtag('config', 'G-D9R7Y5LZJ3');
		</script>
	{/if}
</svelte:head>
